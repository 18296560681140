// Dependency
import React from "react";
import { graphql } from "gatsby";

// Internationalization
import { useTranslation } from 'react-i18next';

// Components
import Layout from "../../components/layouts";
import FooterTrust from "../../components/trustpilot/footer-trustpilot";

// Styles
import * as standardStyles from "./standard.module.scss";

// GraphQL to get Dato data



// Template
const Standard = (props) => {
    // Extract page data
    const pageData = props.data.allDatoCmsStandardPage.nodes;
    // Translation Function
    const { t } = useTranslation();
    //
    return (
        <Layout country={props.pageContext.country} location={props.location}>

            <div className={`${standardStyles.cdStandardPage} cd-dark-mode-dark`}>
                <div className="cd-max-width cd-small-width" dangerouslySetInnerHTML={{__html: pageData[0].content}}>
                </div>
            </div>

            <FooterTrust locale={pageData[0].locale} />

        </Layout>
    )
}

export default Standard;

// GraphQL to get Dato data
export const query = graphql`
  query  ($slug: String!, $locale: String!) {
    allDatoCmsStandardPage(
      filter: {slug: { eq: $slug },locale: {eq: $locale}}
    ) {
      nodes {
        locale
        title
        slug
        content
      }
    }
  }
`;